import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { Suspense, lazy, useState, useEffect } from "react";
import MainLayout from "./components/MainLayout";
import { OpenRoutes } from "./routing/OpenRoutes";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import Dprofile from "./pages/Delivery/Dprofile";
import Ddashboard from "./pages/Delivery/Ddashboard";
import Order from "./pages/Delivery/Orders";
import Dhistort from "./pages/Delivery/Dhistort";
//import HeaderTop from "./pages/Delivery/HeaderTop";
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const Enquiries = lazy(() => import("./pages/Enquiries"));
const Bloglist = lazy(() => import("./pages/Bloglist"));
const Blogcatlist = lazy(() => import("./pages/Blogcatlist"));
const Orders = lazy(() => import("./pages/Orders"));
const Customers = lazy(() => import("./pages/Customers"));
const Colorlist = lazy(() => import("./pages/Colotlist"));
const Categorylist = lazy(() => import("./pages/Categorylist"));
const Taglist = lazy(() => import("./pages/taglist"));
const Brandlist = lazy(() => import("./pages/Brandlist"));
const Productlist = lazy(() => import("./pages/Productlist"));
const Addblog = lazy(() => import("./pages/Addblog"));
const Addblogcat = lazy(() => import("./pages/Addblogcat"));
const BlogSubcategorylist = lazy(() => import("./pages/bSubcategoryList"));
const Addcolor = lazy(() => import("./pages/Addcolor"));
const Addcat = lazy(() => import("./pages/Addcat"));
const AddSubCat = lazy(() => import("./pages/AddSubCat"));
const AddBlogSubCat = lazy(() => import("./pages/AddblogSubcat"));
const AddTag = lazy(() => import("./pages/AddTag"));
const Addbrand = lazy(() => import("./pages/Addbrand"));
const Addproduct = lazy(() => import("./pages/Addproduct"));
const Couponlist = lazy(() => import("./pages/Couponlist"));
const AddCoupon = lazy(() => import("./pages/AddCoupon"));
const ViewEnq = lazy(() => import("./pages/ViewEnq"));
const ViewOrder = lazy(() => import("./pages/ViewOrder"));
const Subcategorylist = lazy(() => import("./pages/subCategorylist"));
const Createdelivery = lazy(() => import("./pages/CreateDeliveryBoy"));
const DeliveryBoyList = lazy(() => import("./pages/DeliveryBoyList"));
const DeliveryBoyEdit = lazy(() => import("./pages/EditDeliveryBoy"));
const AdminOrderManagement = lazy(() => import("./pages/AdminOrderManagement"));



const SuperAdminMainLayout= lazy(()=> import("./components/SuperAdminMainLayout"));
const Register=lazy(()=>import("./pages/superAdmin/Register"));
const VerfyMessage=lazy(()=> import("./pages/superAdmin/verifyMessage"));
const  ResetPassword=lazy(()=> import("./pages/superAdmin/ResetPassword"));
const ForgotPassword=lazy(()=>import("./pages/superAdmin/ForgotPassword"));
const PasswordChangeSuccessPage =lazy(()=>import("./pages/superAdmin/chnagePassword"));
const MerchantList=lazy(()=>import("./pages/superAdmin/mecantList"));
const AdminList=lazy(()=>import("./pages/superAdmin/AdminList"))
const Activity=lazy(()=>import("./pages/superAdmin/adminActivityTrack"));
const AllAdminActivity=lazy(()=>import("./pages/superAdmin/AllAdminActivity"));
const MerchantActivity=lazy(()=>import("./pages/superAdmin/MerchantActivity"));
const EachMerchantActivity=lazy(()=>import("./pages/superAdmin/eachMerchantTracker"));
const DocumentVerify=lazy(()=> import("./pages/superAdmin/verify_doc"));
const EachMerchantStore=lazy(()=>import("./pages/superAdmin/eachMerchantStore"));
const EachActivityDetail=lazy(()=>import("./pages/superAdmin/ActivtyDetail.js"));
const Profile=lazy(()=>import("./pages/superAdmin/profile.js"));
const UserProfile=lazy(()=>import("./pages/superAdmin/UserProfile.js"));
const Chat=lazy(()=>import("./pages/Chat/chat.js"))




const  ProductDetails=lazy(()=> import("./pages/merchant/productdetaile.js"))
const MerchantMainLayout=lazy(()=>import("./components/merchantMainLayout"));
const NewOrders=lazy(()=>import("./pages/merchant/new_order"));
const AddPromotionForm=lazy(()=>import("./pages/merchant/AddPromotion"));
const Promotionlist=lazy(()=>import("./pages/merchant/promotionlist"));
const OrderDetails=lazy(()=> import("./pages/merchant/oreder_Details"));
const ReportingIssues=lazy(()=> import("./pages/merchant/ReportIssue"));
const AddStore =lazy(()=>import("./pages/merchant/AddStore"));
const ListStore=lazy(()=>import("./pages/merchant/ListStore"));
const AddDocument=lazy(()=>import("./pages/merchant/VerifyDoc"));
const StoreProduct=lazy(()=>import("./pages/merchant/storeProduct"));
const UserCount=lazy(()=>import("./pages/superAdmin/superAdminDashboard"));
const  MerchantDashboard=lazy(()=>import("./pages/merchant/MerchantDashboard"));
const EachMerchantProductList=lazy(()=>import("./pages/merchant/eachMerchantProductList"));
const PackageList=lazy(()=>import("./pages/superAdmin/packageList.js"));
const Package=lazy(()=>import("./pages/superAdmin/AddNewPackage.js"));
const PaymentForm=lazy(()=>import("./pages/merchant/PackageSelect.js"));

function App() {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 30000); // Display the loader for 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Suspense
        fallback={
          showLoader && (
            <div className="fixed inset-0 flex justify-center items-center bg-white z-50">
              <div className="relative flex justify-center items-center">
                <div className="absolute animate-spin rounded-full h-36 w-36 border-t-4 border-b-4 border-red-500"></div>
                <img
                  src="https://wibfashion.com/assets/img/logo/nav-log.png"
                  className="rounded-full h-28 w-28"
                  alt="Wub-fashion...."
                />
              </div>
            </div>
          )
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <OpenRoutes>
                <Login />
              </OpenRoutes>
            }
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/ForgotPassword" element={<ForgotPassword/>} />
          <Route path="/PasswordChangeSuccessPage" element={<PasswordChangeSuccessPage/>}/>
          <Route
            path="/admin"
            element={
              <PrivateRoutes>
                <MainLayout />
              </PrivateRoutes>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="enquiries" element={<Enquiries />} />
            <Route path="enquiries/:id" element={<ViewEnq />} />
            <Route path="blog-list" element={<Bloglist />} />
            <Route path="blog" element={<Addblog />} />
            <Route path="blog/:id" element={<Addblog />} />
            <Route path="coupon-list" element={<Couponlist />} />
            <Route path="coupon" element={<AddCoupon />} />
            <Route path="coupon/:id" element={<AddCoupon />} />
               
            <Route path="orders" element={<Orders />} />
            <Route path="order/:id" element={<ViewOrder />} />
            <Route path="customers" element={<Customers />} />
            <Route path="list-color" element={<Colorlist />} />
            <Route path="color" element={<Addcolor />} />
            <Route path="color/:id" element={<Addcolor />} />
            <Route path="list-category" element={<Categorylist />} />
            <Route path="list-subcategory" element={<Subcategorylist />} />
            <Route
              path="blog-subcategory-list"
              element={<BlogSubcategorylist />}
            />
            <Route path="list-tag" element={<Taglist />} />
            <Route path="category" element={<Addcat />} />
            <Route path="category/:id" element={<Addcat />} />
            <Route path="subcategory" element={<AddSubCat />} />
            <Route path="blogSubcategory" element={<AddBlogSubCat />} />
            <Route path="subcategory/:id" element={<AddSubCat />} />
            <Route path="blogSubcategory/:id" element={<AddBlogSubCat />} />
            <Route path="tags" element={<AddTag />} />
            <Route path="tag/:id" element={<AddTag />} />
            <Route path="list-tag" element={<Taglist />} />
            <Route path="list-brand" element={<Brandlist />} />
            <Route path="brand" element={<Addbrand />} />
            <Route path="brand/:id" element={<Addbrand />} />
            <Route path="list-product" element={<Productlist />} />
            <Route path="product" element={<Addproduct />} />
            <Route path="product/:id" element={<Addproduct />} />
            <Route path="createdelivery" element={<Createdelivery />} />
            <Route path="deliveryboylist" element={<DeliveryBoyList />} />
            <Route
              path="delivery-boys/edit/:id"
              element={<DeliveryBoyEdit />}
            />
            <Route
              path="adminOrderManagement"
              element={<AdminOrderManagement />}
            />
          </Route>




          <Route
              path="/merchant"
              element={
            <PrivateRoutes>
                <MerchantMainLayout />
            </PrivateRoutes>
    }
>
  
            <Route index element={<MerchantDashboard/>} />
            <Route path="list-product" element={<EachMerchantProductList />} />
            <Route path="product" element={<Addproduct />} />
            <Route path="product/:id" element={<Addproduct />} />
            <Route path="list-product/:id" element={<ProductDetails />} />
            <Route path="orders" element={<Orders />} />
            <Route path="New_oreder" element={<NewOrders/>}/>
            <Route path="New_oreder/:id" element={<OrderDetails />} />
            <Route path="orders/:id" element={<OrderDetails />} />
            <Route path="blog" element={<Addblog />} />
            <Route path="blog-list" element={<Bloglist />} />
            <Route path="Report" element={<ReportingIssues/>} />
            <Route path="Add-Store" element={<AddStore/>}/>
            <Route path="list-Store" element={<ListStore />} />
            <Route path="list-Store/:id" element={<AddStore />} />
            <Route path="AddDocument" element={<AddDocument/>}/>
            <Route path="list-store/store/:id" element={<StoreProduct/>}/>
            <Route path="payment" element={<PaymentForm/>}/>

         </Route>
         <Route
          path="/superAdmin"
          element={
            <PrivateRoutes>
              <SuperAdminMainLayout />
            </PrivateRoutes>
          }
        >
          <Route index element={<UserCount/>} />
          <Route path="list-brand" element={<Brandlist />} />
          <Route path="register" element={<Register/>} />
          <Route path="message" element={<VerfyMessage/>} />
          <Route path="merchantList" element={<MerchantList/>}/>
          <Route path="merchantList/:id"  element={<EachMerchantActivity/>} />
          <Route path="merchantList/list-Store/:id" element={< EachMerchantStore />} />
          <Route path="merchantList/list-store/:id/store/:id" element={<StoreProduct/>}/>
          <Route path="merchantActivity"  element={<MerchantActivity/>}/>
          <Route path="Admin_Activity" element={<AllAdminActivity/>} />
          <Route path="adminList" element={<AdminList/>}/>
          <Route path="adminList/:id" element={<Activity/>}/>
          <Route path="Verify_doc" element={<DocumentVerify/>} />
          <Route path="activitylog/:id" element={<EachActivityDetail/>} />
          <Route path="profile" element={<Profile />} />
          <Route path="Profile/:id" element={<UserProfile/>}/>

          <Route path="list-color" element={<Colorlist />} />
          <Route path="color" element={<Addcolor />} />
          <Route path="color/:id" element={<Addcolor />} />
          <Route path="blog-list" element={<Bloglist />} />
          <Route path="blog" element={<Addblog />} />
          <Route path="blog/:id" element={<Addblog />} />
          <Route path="tags" element={<AddTag />} />
          <Route path="tag/:id" element={<AddTag />} />
          <Route path="list-tag" element={<Taglist />} />
          <Route path="list-product" element={<Productlist />} />
          <Route path="product" element={<Addproduct />} />
          <Route path="product/:id" element={<Addproduct />} />
          <Route path="list-brand" element={<Brandlist />} />
          <Route path="brand" element={<Addbrand />} />
           <Route path="list-category" element={<Categorylist />} />
            <Route path="list-subcategory" element={<Subcategorylist />} />
            <Route
              path="blog-subcategory-list"
              element={<BlogSubcategorylist />}
            />
            <Route path="list-tag" element={<Taglist />} />
            <Route path="category" element={<Addcat />} />
            <Route path="category/:id" element={<Addcat />} />
            <Route path="subcategory" element={<AddSubCat />} />
            <Route path="blogSubcategory" element={<AddBlogSubCat />} />
            <Route path="subcategory/:id" element={<AddSubCat />} />
            <Route path="blogSubcategory/:id" element={<AddBlogSubCat />} />
            <Route path="tags" element={<AddTag />} />
            <Route path="tag/:id" element={<AddTag />} />
            <Route path="list-tag" element={<Taglist />} />
            <Route path="brand/:id" element={<Addbrand />} />
            <Route path="list-category" element={<Categorylist />} />
            <Route path="list-subcategory" element={<Subcategorylist />} />
            <Route
              path="blog-subcategory-list"
              element={<BlogSubcategorylist />}
            />
            <Route path="list-tag" element={<Taglist />} />
            <Route path="category" element={<Addcat />} />
            <Route path="category/:id" element={<Addcat />} />
            <Route path="subcategory" element={<AddSubCat />} />
            
            <Route path="subcategory/:id" element={<AddSubCat />} />
            <Route path="customers" element={<Customers />} />
            <Route path="deliveryboylist" element={<DeliveryBoyList />} />
            <Route path="delivery-boys/edit/:id" element={<DeliveryBoyEdit />}/>
            <Route path="coupon-list" element={<Couponlist />} />
            <Route path="coupon" element={<AddCoupon />} />
            <Route path="coupon/:id" element={<AddCoupon />} />
            <Route path="blog-list" element={<Bloglist />} />
            <Route path="blog" element={<Addblog />} />
            <Route path="blog/:id" element={<Addblog />} />
            <Route path="blogSubcategory" element={<AddBlogSubCat />} />
            
            <Route path="blogSubcategory/:id" element={<AddBlogSubCat />} />
            <Route path="enquiries" element={<Enquiries />} />
            <Route path="enquiries/:id" element={<ViewEnq />} />
            <Route path="List-package" element={<PackageList/>} />
            <Route path="Package/:id" element={<Package/>} />
            <Route path="Package" element={<Package/>} />
            <Route path="chat" element={<Chat/>}/>

            
          
        </Route>



          
          <Route path="/delivery" element={<Ddashboard />} />
          <Route path="/delivery/orders" element={<Order />} />
          <Route path="/delivery/proflle" element={<Dprofile />} />
          <Route path="/delivery/history" element={<Dhistort />} />
          
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
